<template>
	<!-- eslint-disable -->

	<v-container v-if="hasPermission($route.meta.permission)" class="pa-6" tag="section" >
		<v-row class="none-printable">
				<v-col cols="10" sm="8" class="d-flex align-center me-auto">
									<span
										>Invoices
										<v-icon>mdi-arrow-collapse-right</v-icon></span
									>
									<span class="text-sm-h2 text-h3"> Invoices Details{{invoice.status!==undefined && payable ? ' & Payment':''}}</span>
								</v-col>

		</v-row>
		<v-row class="none-printable">
			<v-col cols="10">
				<span class="text-h">{{ moment().format("MMM Y") }}</span>
			</v-col>
								<v-col cols="2" class="d-flex justify-end pa-0">
									<v-btn
										small
										class="blue-grey lighten-4 ma-2"
										fab
										text
										icon
										to="/invoices"
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-col>
		</v-row>
		<v-row  class="munjz-bg-grey rounded-lg align-center none-printable">
			<v-col cols="2">
				<v-btn
				id="pay"
				v-if=" invoice.status!==undefined && payable"
					elevation="0"
					:disabled="hasPermission('Send Invoices')"
					color="primary"
					@click="invoicePaymentDialog = true"
					> <v-icon left>mdi-send</v-icon>Send Payment Receipt</v-btn
				>

				<v-btn id="download" :href="`https://munjz.net/api/v3/partner-invoice-print/${invoiceId}`" outlined color="primary">
					<v-row>
						
						<v-col>
							
							<span> Download</span><br><span>
								فاتورة ضريبية
							</span>
						<v-icon  color="primary">mdi-download</v-icon> 
						</v-col>
					</v-row>
					</v-btn
				>
			</v-col>

			<v-col class="" cols="2">
				<h4>Export</h4>
				<h5>{{ moment().format("DD MMM Y") }}</h5>
			</v-col>
			<v-col cols="2">
				<h4>Total Amount with VAT</h4>
				<h5>{{ invoice.final_total_price_with_vat }}</h5>
			</v-col>
			<v-col class="2">
				<h4>Total Amount</h4>
				<h5>{{ invoice.final_total_price }}</h5>
			</v-col>
			<v-col cols="1" class="2">
				<h4>VAT</h4>
				<h5>{{ invoice.final_total_vat }}</h5>
			</v-col>
			<v-col  class="2">
				<h4>
					Status
				</h4>
		
					<h5 class="red--text lighten-1" >
					{{ invoice.status==1? $t("paid"): invoice.status==3? ` ${$t('unpaid ( waiting for approval )')}`:$t("unpaid") }}
				</h5>


			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3" class="me-auto">
				<img src="../../../assets/munjz-logo.png"  height="82" />
			</v-col>
			<v-col cols="auto" dir="rtl" >
					<span class="main-text">شركة تطبيق منجز للصيانة والتشغيل</span>
					<br />
					<span>Munjz for maintenance & operations LLC</span>
					<br />
					<span dir="ltr" class="submain-text">CR 2050221445</span>
				</div>
			</v-col>
			<v-col cols="12" class="d-flex justify-center" dir="rtl">
					<span class="main-text" style="font-size:16pt">
					فاتورة
					</span>
			</v-col>
			<v-col cols="12"  dir="rtl">
					<span class="main-text">الشركة: تطبيق منجز للصيانة والتشغيل</span>
			</v-col>
			<v-col cols="12"  dir="rtl">
				<table class="pa-3 rounded-lg" style="table-layout: fixed;
					border:1px solid lightgrey;
					
                    width: 90%">
					<tr  >
						<td width="120" class="submain-text" >العنوان</td>
						<td>الدمام شارع الامير محمد بن فهد</td>
					<td width="120" class="submain-text"> الرقم الموحد
						</td>
						<td>920010860</td></tr>
						<tr>
							<td  class="submain-text" >
						الرقم الضريبي
							</td>
							<td>300008965073013</td>
							<td class="submain-text">
						السجل التجاري  
							</td>
							<td>2050221445</td>
						</tr>
					
				</table>
			</v-col>
			<v-col dir="rtl" cols="12">
	<span class="main-text"> إسم العميل: {{ invoice.company_name }}</span>
			</v-col>
					<v-col cols="12"  dir="rtl">
				<table class="pa-3 rounded-lg" style="table-layout: fixed;
					border:1px solid lightgrey;
					
                    width: 90%">
					<tr  >
						<td width="120" class="submain-text" >الرقم الضريبي</td>
						<td>2050221445</td>
					<td width="120" class="submain-text"> عنوان العميل
						</td>
						<td>الدمام شارع الامير محمد بن فهد</td></tr>
						<tr>
							<td  class="submain-text" >
						تاريخ الاصدار
							</td>
							<td>{{moment().format('MMM YYYY')}}</td>
							<td class="submain-text">
						رقم الفاتورة 
							</td>
							<td>{{
							invoice.partner_invoice_no
						}}</td>
						</tr>
						<tr>
							<td>تاريخ الاستحقاق</td>
							<td>{{
							dueDate
						}}</td>
						</tr>
					
				</table>
			</v-col>
					<v-col cols="12" dir="rtl">
					<span class="main-text primary--text" style="font-size:16pt">
						تفاصيل الفاتورة
					</span>
			</v-col>
			<v-col cols="12" dir="rtl">
				<div class="pa-3 rounded-lg" style="border:1px solid lightgrey">
			<v-data-table
			
			      :headers="headers"
				  :hide-default-footer="true"
      			:items="orders"
			>
			</v-data-table>
			</div>
			</v-col>
			

		
		</v-row>

<v-row dir="rtl">

			<v-col cols="5" dir="rtl" class="d-flex justify-start ms-auto">
						<table width="100%" class="pa-3 rounded-lg" style="border:1px solid lightgrey">
					<tr><td width="60%">القيمة بدون ضريبة </td>
					<td>{{invoice.final_total_price}}</td></tr>
						<tr><td width="180">الخصم </td>
					<td>0</td></tr>
						<tr><td >القيمة بعد الخصم </td>
					<td>{{invoice.final_total_price}}</td></tr>
						<tr><td >قيمة الضريبة المضافة 15 % </td>
					<td>{{invoice.final_total_vat}}</td></tr>
							<tr><td class="submain-text" > الإجمالي مع الضريبة</td>
					<td>{{invoice.final_total_price_with_vat}}</td></tr>
				
				
				</table>
			</v-col>
											<v-col cols="6" dir="rtl" class="d-flex align-end justify-start">
					<table width="100%" class="pa-3 rounded-lg" style="border:1px solid lightgrey">
										<tr><td width="70%" class="submain-text">المبلغ المدفوع</td>
										<td>{{invoice.paid_amount}}</td></tr>
										<tr><td class="main-text">المبلغ المتبقي</td>
										<td>{{invoice.amount-invoice.paid_amount}}</td></tr>
					</table>

				</v-col>
							<v-col dir="ltr" cols="12">
				<v-btn
				id="pay"
				v-if="invoice.status!==undefined && payable "
					elevation="0"
					color="primary"
					:disabled="hasPermission('Send Invoices')"
					@click="invoicePaymentDialog = true"
					> <v-icon left>mdi-send</v-icon>Send Payment Receipt</v-btn
				>
			</v-col>
</v-row>

		<v-dialog 	overlay-color="munjz-accent-2"
			overlay-opacity="0.7"
			persistent v-model="invoicePaymentDialog" max-width="690">
			<v-container class="pa-0">
					<v-row class="ma-0">
						<v-col class="pa-0">
						<v-card class="rounded-lg pa-3" flat>
							<v-row class="ma-0">
			<v-col cols="10">
				<h1>Send Payment Request</h1>
			</v-col>
								<v-col cols="2" class="d-flex justify-end pa-0">
									<v-btn
										small
										class="blue-grey lighten-4 ma-2"
										fab
										text
										icon
										@click="invoicePaymentDialog = false"
										><v-icon>mdi-close</v-icon></v-btn
									>
									</v-col>
									</v-row>
								<v-card-text>
									<v-row>
									<v-col>
									<v-radio-group class="d-flex align-center" hide-details	v-model="selectedBank">
											<template v-slot:prepend>
												<v-col class="pa-0  mt-4 align-end">
												<v-avatar size="24" class="mb-2">
													<img :src="banks[0].avatar" />
												</v-avatar>
												<v-avatar size="24">
													<img :src="banks[1].avatar" />
												</v-avatar>
												</v-col>
											</template>
											<table>
												<tr><td>Bank</td> <td>Name</td><td>Account</td></tr>
												<tr><td>	<v-radio label="Alrajhi Bank" :value="1"></v-radio></td>
												<td>Munjz Application</td>
												<td><span class="text-h4 text-weight-bold">503608010707711</span></td></tr>
												<tr><td><v-radio label="Alinmaa Bank" :value="2"></v-radio></td>
												<td>Munjz Application</td>
												<td><span class="text-h4 text-weight-bold">68206660333000</span></td></tr>
											</table>					
										</v-radio-group>
										</v-col>
										</v-row>
							<v-row>
							<v-col>
							<h4>Upload your receipt please</h4>
						</v-col>
						<v-col cols="12">
							<v-file-input
								v-model="invoiceFile"
								placeholder="Upload Receipt"
								label="Upload Receipt File"
								outlined
								prepend-icon="mdi-image-plus"
							>
								<template v-slot:selection="{ text }">
									<v-chip small label color="primary">
										{{ text }}
									</v-chip>
								</template>
							</v-file-input>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
<span class="text-h4">Transaction Amount: {{invoiceAmount}} SAR</span>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="3" v-if="hasPermission('Send Invoices')">
							<v-btn @click="payInvoice" color="primary">
							<v-icon left>mdi-plus</v-icon>	Send Payment
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			</v-col>
			</v-row>
			</v-container>
		</v-dialog>

		    <v-snackbar
			top class="rounded-pill"
      v-model="paymentSnackbar.show"
     color="green"
    >
    <v-icon left color="white">mdi-check</v-icon>
   {{paymentSnackbar.message}}
    </v-snackbar>
	</v-container>
</template>

<script>
import moment from "moment";

export default {
	props: {
		id: {
			type: String,
			reuired: true
		}
	},
	name: "InvoiceDetail",
	data() {
		return {
			paymentSnackbar: {
				show: false,
				message: String
			},
			dueDate: null,
			invoiceAmount: null,
			headers: [
				{
					text: "تفاصيل الخدمة",
					align: "start",
					sortable: false,
					value: "main_service",
					class: "munjz-bg-grey"
				},
				{
					text: "رقم الطلب",
					align: "start",
					sortable: false,
					value: "order_no",
					class: "munjz-bg-grey"
				},
				{
					text: "القيمة",
					align: "start",
					sortable: false,
					value: "final_total_price",
					class: "munjz-bg-grey"
				},

				{
					text: "القيمة الإجمالية",
					align: "start",
					sortable: false,
					value: "final_total_price_with_vat",
					class: "munjz-bg-grey"
				}
			],

			services: [
				{
					name: "service 1"
				}
			],
			moment: moment,
			total: {},
			invoice: {},
			orders: [],
			invoiceId: this.$props.id,
			moment: moment,
			selectedBank: 1,
			invoiceFile: null,
			banks: [
				{
					id: 1,
					name: "Alrajhi Bank",
					avatar: require("../../../assets/alrajhi.jpg")
				},
				{
					id: 2,
					name: "Alinma Bank",
					avatar: require("../../../assets/alinmaa.jpg")
				}
			],
			invoicePaymentDialog: false,
			token: localStorage.getItem("token"),
			user_id: localStorage.getItem("userid")
		};
	},

	created() {},
	mounted() {
		this.getInvoiceOrders();

		moment.updateLocale("en", {
			months: [
				"يناير",
				"فبراير",
				"مارس",
				"أبريل",
				"مايو",
				"يونيو",
				"يوليو",
				"أغسطس",
				"سبتمبر",
				"أكتوبر",
				"نوفمبر",
				"ديسمبر"
			]
		});
	},
	computed: {
		payable() {
			return this.invoice.status !== 1 && this.invoice.status !== 3;
		},
			downloadInvoiceAsPdf() {
		
			return `https://munjz.net/api/v3/partner-invoice-print/${this.invoiceId}`
		},
	},
	methods: {
		payInvoice() {
			let bodyFormData = new FormData();
			bodyFormData.append("transfer_method", this.selectedBank);
			bodyFormData.append("paid_amount", this.invoiceAmount);
			bodyFormData.append("payment_reference", "");
			bodyFormData.append("invoice_file", this.invoiceFile);
			bodyFormData.append("invoice_id", this.invoiceId);
			axios({
				method: "post",
				url: "/api/v3/b2b/partner-paid-invoice",
				data: bodyFormData,
				headers: {
					"Content-Type": "multipart/form-data",
					"X-Authorization": this.token
				}
			})
				.then(res => {
					this.paymentSnackbar;
					this.paymentSnackbar.message = res.data.message;
					this.paymentSnackbar.show = true;
					this.invoicePaymentDialog = false;
				})
				.catch(function(response) {
					//handle error
				})
				.finally(() => {});
		},

	

		getInvoiceOrders() {
			axios
				.get(`/api/v3/b2b/partner-invoice-orders/${this.invoiceId}`, {
					headers: { "X-Authorization": this.token },
					params: {
						partner_id: this.user_id
					}
				})
				.then(res => {
					this.invoice = res.data.invoice;
					this.orders = this.invoice.orders;
					this.invoiceAmount = this.invoice.amount;
					this.dueDate = moment(this.invoice.created_at.date).format(
						"MMMM YYYY"
					);
				})
				.catch(err => console.log(err))
				.finally(() => (this.dialog = false));
		}
	}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

* {
	font-family: "Almarai", sans-serif !important;
}

@media print {
	@page {
		size: auto;
		margin: 0mm;
	}

	.v-navigation-drawer,
	#download,
	#pay,
	#no-print-info,
	#munjz-nav-bar,
	meta,
	.none-printable {
		display: none !important;
	}
	* {
		transform: scale(0.99);
	}
}
.main-text {
	font-size: 14pt;
	color: #000000;
	font-weight: 800;
}
.submain-text {
	color: #000000;
	font-size: 11pt;
}
</style>
